export const allCards = [
  {
      "cardName": "ace",
      "cardNumber": 1,
      "suit": "spades",
      "number": 1,
      "value": 1
  },
  {
      "cardName": "two",
      "cardNumber": 2,
      "suit": "spades",
      "number": 2,
      "value": 2
  },
  {
      "cardName": "three",
      "cardNumber": 3,
      "suit": "spades",
      "number": 3,
      "value": 3
  },
  {
      "cardName": "four",
      "cardNumber": 4,
      "suit": "spades",
      "number": 4,
      "value": 4
  },
  {
      "cardName": "five",
      "cardNumber": 5,
      "suit": "spades",
      "number": 5,
      "value": 5
  },
  {
      "cardName": "six",
      "cardNumber": 6,
      "suit": "spades",
      "number": 6,
      "value": 6
  },
  {
      "cardName": "seven",
      "cardNumber": 7,
      "suit": "spades",
      "number": 7,
      "value": 7
  },
  {
      "cardName": "eight",
      "cardNumber": 8,
      "suit": "spades",
      "number": 8,
      "value": 8
  },
  {
      "cardName": "nine",
      "cardNumber": 9,
      "suit": "spades",
      "number": 9,
      "value": 9
  },
  {
      "cardName": "ten",
      "cardNumber": 10,
      "suit": "spades",
      "number": 10,
      "value": 0
  },
  {
      "cardName": "jack",
      "cardNumber": 11,
      "suit": "spades",
      "number": 11,
      "value": 0
  },
  {
      "cardName": "queen",
      "cardNumber": 12,
      "suit": "spades",
      "number": 12,
      "value": 0
  },
  {
      "cardName": "king",
      "cardNumber": 13,
      "suit": "spades",
      "number": 13,
      "value": 0
  },
  {
      "cardName": "ace",
      "cardNumber": 1,
      "suit": "hearts",
      "number": 14,
      "value": 1
  },
  {
      "cardName": "two",
      "cardNumber": 2,
      "suit": "hearts",
      "number": 15,
      "value": 2
  },
  {
      "cardName": "three",
      "cardNumber": 3,
      "suit": "hearts",
      "number": 16,
      "value": 3
  },
  {
      "cardName": "four",
      "cardNumber": 4,
      "suit": "hearts",
      "number": 17,
      "value": 4
  },
  {
      "cardName": "five",
      "cardNumber": 5,
      "suit": "hearts",
      "number": 18,
      "value": 5
  },
  {
      "cardName": "six",
      "cardNumber": 6,
      "suit": "hearts",
      "number": 19,
      "value": 6
  },
  {
      "cardName": "seven",
      "cardNumber": 7,
      "suit": "hearts",
      "number": 20,
      "value": 7
  },
  {
      "cardName": "eight",
      "cardNumber": 8,
      "suit": "hearts",
      "number": 21,
      "value": 8
  },
  {
      "cardName": "nine",
      "cardNumber": 9,
      "suit": "hearts",
      "number": 22,
      "value": 9
  },
  {
      "cardName": "ten",
      "cardNumber": 10,
      "suit": "hearts",
      "number": 23,
      "value": 0
  },
  {
      "cardName": "jack",
      "cardNumber": 11,
      "suit": "hearts",
      "number": 24,
      "value": 0
  },
  {
      "cardName": "queen",
      "cardNumber": 12,
      "suit": "hearts",
      "number": 25,
      "value": 0
  },
  {
      "cardName": "king",
      "cardNumber": 13,
      "suit": "hearts",
      "number": 26,
      "value": 0
  },
  {
      "cardName": "ace",
      "cardNumber": 1,
      "suit": "diamonds",
      "number": 27,
      "value": 1
  },
  {
      "cardName": "two",
      "cardNumber": 2,
      "suit": "diamonds",
      "number": 28,
      "value": 2
  },
  {
      "cardName": "three",
      "cardNumber": 3,
      "suit": "diamonds",
      "number": 29,
      "value": 3
  },
  {
      "cardName": "four",
      "cardNumber": 4,
      "suit": "diamonds",
      "number": 30,
      "value": 4
  },
  {
      "cardName": "five",
      "cardNumber": 5,
      "suit": "diamonds",
      "number": 31,
      "value": 5
  },
  {
      "cardName": "six",
      "cardNumber": 6,
      "suit": "diamonds",
      "number": 32,
      "value": 6
  },
  {
      "cardName": "seven",
      "cardNumber": 7,
      "suit": "diamonds",
      "number": 33,
      "value": 7
  },
  {
      "cardName": "eight",
      "cardNumber": 8,
      "suit": "diamonds",
      "number": 34,
      "value": 8
  },
  {
      "cardName": "nine",
      "cardNumber": 9,
      "suit": "diamonds",
      "number": 35,
      "value": 9
  },
  {
      "cardName": "ten",
      "cardNumber": 10,
      "suit": "diamonds",
      "number": 36,
      "value": 0
  },
  {
      "cardName": "jack",
      "cardNumber": 11,
      "suit": "diamonds",
      "number": 37,
      "value": 0
  },
  {
      "cardName": "queen",
      "cardNumber": 12,
      "suit": "diamonds",
      "number": 38,
      "value": 0
  },
  {
      "cardName": "king",
      "cardNumber": 13,
      "suit": "diamonds",
      "number": 39,
      "value": 0
  },
  {
      "cardName": "ace",
      "cardNumber": 1,
      "suit": "clubs",
      "number": 40,
      "value": 1
  },
  {
      "cardName": "two",
      "cardNumber": 2,
      "suit": "clubs",
      "number": 41,
      "value": 2
  },
  {
      "cardName": "three",
      "cardNumber": 3,
      "suit": "clubs",
      "number": 42,
      "value": 3
  },
  {
      "cardName": "four",
      "cardNumber": 4,
      "suit": "clubs",
      "number": 43,
      "value": 4
  },
  {
      "cardName": "five",
      "cardNumber": 5,
      "suit": "clubs",
      "number": 44,
      "value": 5
  },
  {
      "cardName": "six",
      "cardNumber": 6,
      "suit": "clubs",
      "number": 45,
      "value": 6
  },
  {
      "cardName": "seven",
      "cardNumber": 7,
      "suit": "clubs",
      "number": 46,
      "value": 7
  },
  {
      "cardName": "eight",
      "cardNumber": 8,
      "suit": "clubs",
      "number": 47,
      "value": 8
  },
  {
      "cardName": "nine",
      "cardNumber": 9,
      "suit": "clubs",
      "number": 48,
      "value": 9
  },
  {
      "cardName": "ten",
      "cardNumber": 10,
      "suit": "clubs",
      "number": 49,
      "value": 0
  },
  {
      "cardName": "jack",
      "cardNumber": 11,
      "suit": "clubs",
      "number": 50,
      "value": 0
  },
  {
      "cardName": "queen",
      "cardNumber": 12,
      "suit": "clubs",
      "number": 51,
      "value": 0
  },
  {
      "cardName": "king",
      "cardNumber": 13,
      "suit": "clubs",
      "number": 52,
      "value": 0
  }
]